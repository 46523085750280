import React from 'react'
import Layout from "../components/App/Layout"
import NavbarThree from "../components/App/NavbarThree"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import TeamMembers from '../components/AboutUs/TeamMembers'
import Funfacts from '../components/Common/Funfacts';
 
const AboutUs = () => {
    return (
        <Layout>
            <NavbarThree />
            <PageBanner
                pageTitle="About Us" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="About Us" 
            />
            <TeamMembers />
            <Funfacts />
            <Footer />
        </Layout>
    );
}

export default AboutUs;