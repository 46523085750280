import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import scientist9 from '../../assets/images/scientist/scientist9.jpg'
import scientist10 from '../../assets/images/scientist/scientist10.jpg'
import scientist11 from '../../assets/images/scientist/scientist11.jpg'
import shape1 from '../../assets/images/shape/map-shape1.png'
import shape4 from '../../assets/images/shape/vector-shape4.png'
import shape5 from '../../assets/images/shape/vector-shape5.png'

const TeamMembers = () => {
    return (
        <div className="scientist-area pt-100 pb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12">
                        <div className="scientist-box-list">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6 col-md-6 offset-lg-1">
                                    <div className="single-scientist-item">
                                        <img src={scientist9} alt="banner" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <div className="single-scientist-item">
                                        <img src={scientist10} alt="banner" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-12 col-md-6 offset-lg-0 offset-md-3">
                                    <div className="single-scientist-item">
                                        <img src={scientist11} alt="banner" />
                                    </div>
                                </div>
                            </div>

                            <div className="map-shape1">
                                <img src={shape1} alt="banner" />
                            </div>
                            <div className="vector-shape5">
                                <img src={shape5} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-12">
                        <div className="scientist-section-title">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" />
                                About us
                            </span>
                            <h2>We are the best fit for your SaaS projects</h2>
                            <p>                            
                                Brooklime Software Design team consists of senior industry experts with over 10 years of experience in the development and design of real-life applications. We built many SaaS applications from scratch. We cover all areas of SaaS system development and maintenance:
                            </p>
                            <p className="about-text">
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Architecture</li>
                                    <li><i className="flaticon-tick"></i> Development</li>
                                    <li><i className="flaticon-tick"></i> Infrastructure</li>
                                    <li><i className="flaticon-tick"></i> Billing</li>
                                    <li><i className="flaticon-tick"></i> Maintenance</li>
                                </ul>
                            </p>
                            <p>
                                We use state-of-the-art tools and practices to guarantee the security of your data. Your software project is in good hands. You always have up-to-date information about the performance of your project. We use only transparent project management practices.
                            </p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="vector-shape4">
                <img src={shape4} alt="banner" />
            </div>
        </div>
    )
}

export default TeamMembers